// src/components/FAQ.js
import React, { useState } from 'react';

function FAQ() {
  const faqs = [
    {
      question: 'What types of work visas exist for working in Europe?',
      answer: `There are several types of work visas in Europe, depending on the type of job and country. The most common types are:

- National visa (type D)** for long-term stays (over 90 days).
- Work visa for highly qualified specialists** (such as the EU Blue Card).
- Seasonal work visa** — for temporary workers in agriculture or tourism.
- Work visa for occupation-specific specialists** — often issued when employers face a shortage of local candidates for specific professions.`,
    },
    {
      question: 'What documents are typically required to apply for a work visa in Europe?',
      answer: `The main list of documents required for a work visa application may include:

- A passport (valid beyond the intended duration of stay).
- An employment contract from a European employer.
- A completed visa application form.
- Educational and professional qualification documents.
- Health insurance.
- Financial documents showing the applicant has sufficient funds to stay in the country.
- Photographs that meet visa requirements.`,
    },
    {
      question: 'Do we provide documents for the visa application?',
      answer: 'Yes, we will provide you with a complete package from the employer to obtain the visa and assist you in submitting your application.',
    },
    {
      question: 'What do we need to start the process?',
      answer: 'You need to submit an application on our website, after which our consultant will contact you, provide you with all the necessary information, help you find a suitable job, and start the process.',
    },
    {
      question: 'How long does the employment and document preparation process take for a visa application?',
      answer: 'Depending on the employer and the country of application, the process can take from 7 business days to a month and a half.',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-neutral-100 py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Frequently Asked Questions</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
              <button
                className="w-full text-left px-6 py-4 focus:outline-none flex justify-between items-center"
                onClick={() => toggleFAQ(index)}
              >
                <span className="font-semibold">{faq.question}</span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-300 ${
                    activeIndex === index ? 'rotate-180' : 'rotate-0'
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 border-t">
                  <p className="text-gray-700 whitespace-pre-line">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQ;
