// src/components/Services.js
import React from 'react';
import { FaFileAlt, FaUserTie, FaPaperPlane } from 'react-icons/fa';

function Services() {
  const services = [
    {
      title: 'Visa Consultation',
      description: 'Expert advice on the visa application process.',
      image: 'images/service1.jpg',
      icon: <FaFileAlt className="text-primary w-8 h-8 mb-4 mx-auto" />,
    },
    {
      title: 'Documentation Assistance',
      description: 'We help you gather and prepare all necessary documents.',
      image: 'images/service2.jpg',
      icon: <FaUserTie className="text-primary w-8 h-8 mb-4 mx-auto" />,
    },
    {
      title: 'Application Submission',
      description: 'We handle the submission of your visa application.',
      image: 'images/service3.jpg',
      icon: <FaPaperPlane className="text-primary w-8 h-8 mb-4 mx-auto" />,
    },
  ];

  return (
    <section className="container mx-auto py-12">
      <h2 className="text-4xl font-bold text-center mb-8">Our Services</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6 text-center">
              <div>{service.icon}</div>
              <h3 className="text-2xl font-bold mb-2">{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
