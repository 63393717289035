// src/components/Process.js
import React from 'react';
import { FaRegHandshake, FaFileAlt, FaPaperPlane, FaRegSmile } from 'react-icons/fa';

function Process() {
  const steps = [
    {
      icon: <FaRegHandshake className="text-secondary w-16 h-16 mx-auto mb-4" />,
      title: 'Step 1: Consultation',
      description: 'Discuss your visa needs with our experts.',
    },
    {
      icon: <FaFileAlt className="text-secondary w-16 h-16 mx-auto mb-4" />,
      title: 'Step 2: Documentation',
      description: 'Gather and prepare all necessary documents.',
    },
    {
      icon: <FaPaperPlane className="text-secondary w-16 h-16 mx-auto mb-4" />,
      title: 'Step 3: Submission',
      description: 'We submit your application to the authorities.',
    },
    {
      icon: <FaRegSmile className="text-secondary w-16 h-16 mx-auto mb-4" />,
      title: 'Step 4: Approval',
      description: 'Receive your visa and start your journey!',
    },
  ];

  return (
    <section className="container mx-auto py-12">
      <h2 className="text-4xl font-bold text-center mb-8">Our Process</h2>
      <div className="grid md:grid-cols-4 gap-8">
        {steps.map((step, index) => (
          <div key={index} className="text-center p-6">
            <div>{step.icon}</div>
            <h3 className="text-2xl font-bold mb-2">{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Process;
