// src/pages/ContactUs.js
import React from 'react';

function ContactUs() {
  return (
    <main className="container mx-auto p-8 mt-20">
      <h2 className="text-4xl font-bold mb-6">Contact Us</h2>
      <p className="text-lg mb-4">
        Feel free to reach out to us through any of the following methods:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>Phone: +48 734 951 354</li>
        <li>Email: info@eurovisahelp.com</li>
        <li>Address: Rondo Daszyńskiego 1, 00-843 Warszawa</li>
      </ul>

      {/* Встроенная карта Google */}
      <div className="map-container" style={{ height: '450px', width: '100%' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.7288633642465!2d20.983225277450188!3d52.230143671986774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecd8154edd2d1%3A0x36a6fe8be25acc0!2srondo%20Daszy%C5%84skiego%201%2C%2000-838%20Warszawa!5e0!3m2!1sru!2spl!4v1730317697335!5m2!1sru!2spl"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </main>
  );
}

export default ContactUs;
