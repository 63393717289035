// src/components/Advantages.js
import React from 'react';
import { FaUserTie, FaRocket, FaHeadset, FaLock } from 'react-icons/fa';

function Advantages() {
  const advantages = [
    {
      icon: <FaUserTie className="text-primary w-16 h-16 mx-auto mb-4" />,
      title: 'Expertise',
      description: 'Years of experience in visa processing.',
    },
    {
      icon: <FaRocket className="text-primary w-16 h-16 mx-auto mb-4" />,
      title: 'Fast Processing',
      description: 'Quick and efficient visa application handling.',
    },
    {
      icon: <FaHeadset className="text-primary w-16 h-16 mx-auto mb-4" />,
      title: '24/7 Support',
      description: 'Our team is available around the clock to assist you.',
    },
    {
      icon: <FaLock className="text-primary w-16 h-16 mx-auto mb-4" />,
      title: 'Secure',
      description: 'We ensure the confidentiality of your personal information.',
    },
  ];

  return (
    <section className="bg-neutral py-12">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8">Why Choose Us</h2>
        <div className="grid md:grid-cols-4 gap-8">
          {advantages.map((advantage, index) => (
            <div key={index} className="text-center p-6">
              <div>{advantage.icon}</div>
              <h3 className="text-2xl font-bold mb-2">{advantage.title}</h3>
              <p>{advantage.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Advantages;
