// src/components/HeroSection.js
import React from 'react';
import { motion } from 'framer-motion';

function HeroSection() {
  return (
    <section
      className="relative bg-cover bg-center h-screen"
      style={{ backgroundImage: `url('/images/hero.jpg')` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center text-white px-4">
          <motion.h1
            className="text-5xl font-bold mb-4"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Your Gateway to Europe
          </motion.h1>
          <motion.p
            className="text-2xl mb-8"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            We assist citizens of India, Nepal and other countries in obtaining European visas.
          </motion.p>
          <motion.a
            href="/application-form"
            className="bg-secondary text-dark px-8 py-4 rounded-full hover:bg-yellow-500 transition duration-300 font-bold"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Apply Now
          </motion.a>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
