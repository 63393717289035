// src/data/vacancies.js
import vacancy1Image from '../images/vacancy1.jpg';
import vacancy2Image from '../images/vacancy2.jpg';
import vacancy3Image from '../images/vacancy3.jpg';
import vacancy4Image from '../images/vacancy4.jpg';
import vacancy5Image from '../images/vacancy5.jpg';
import vacancy6Image from '../images/vacancy6.jpg';
import vacancy7Image from '../images/vacancy7.jpg';
export const vacancies = [
    // Вакансии в Польше
    {
      id: 1,
      country: 'poland',
      title: 'Assembly of Parts for Audi, BMW, Toyota Cars',
      image: vacancy1Image,
      shortDescription: 'Assembling and sorting parts, quality control of products.',
      fullDescription: `
  **Responsibilities:** Assembling and sorting parts, quality control of products.
  
  - Men, women, and couples up to 54-55 years old are welcome.
  - Parts are made of plastic, non-bulky, and the work is not heavy. Leather upholstery process for car parts is done exclusively by men.
  - No prior experience is needed; training is provided.
  - Factories are located in Skoczów, Bażanowice, and Ustroń.
    
  **Wages:**
  
  - 21 PLN net/hour – for JY Konsol
  - 23.50 PLN net/hour under a contract (Umowa zlecenia)
  - The leather upholstery process is paid at a higher rate.
    
  **Opportunity to work up to 300 hours per month!**
  
  **Working hours:**
  
  - 6:00-18:00, 18:00-06:00, Mon-Fri, with optional Saturday shifts.
    
  **Additional Information:**
  
  - Weekly advances are available.
  - We assist with residence permits.
  - Accommodation costs 350-450 PLN.
      `,
    },
    {
      id: 2,
      country: 'poland',
      title: 'Employment in Zabrze in the Automotive Industry',
      image: vacancy2Image,
      shortDescription: 'Production of car parts. Light work suitable for both men and women.',
      fullDescription: `
  **Work involves the production of car parts.** Light work suitable for both men and women.
  
  - Two shifts: 6:00-18:00 and 18:00-6:00, weekly rotation from Monday to Friday with optional Saturday work.
  - Accommodation near the workplace, with rooms for 2-4 people in two-bedroom apartments.
  
  **Wage:**
  
  - 21.70 PLN net/hour.
  - For more than 240 hours per month, there is a 200 PLN bonus and additional factory bonuses (up to 1,000 PLN).
    
  **Benefits:**
  
  - After two months, employees receive a coffee card with a 50 PLN monthly refill; coffee costs 2 PLN.
  - Housing costs 500 PLN per month, including utilities (water, electricity, gas, waste).
  - Separate apartments are available for couples.
  - Self-service; fully paid breaks.
  
  **Additional Information:**
  
  - The work process is exactly as shown in the video attached to this link: [Video](https://youtube.com/watch?v=7o8krqQa6CY&feature=share).
      `,
    },
    {
      id: 3,
      country: 'poland',
      title: 'Biedronka Warehouses',
      image: vacancy3Image,
      shortDescription: 'Order picking, working with forklifts in various locations.',
      fullDescription: `
  **Locations:** Sosnowiec, Ruda Śląska (near Katowice), and Sieradz (near Łódź)
  
  **Requirements:**
  
  - Work for men up to 45 years old
  - Order picking, working with forklifts
  
  **Wages:**
  
  - **First month:** 20.00 PLN net/hour (guaranteed rate)
  - **Second month onward:** 25-27 PLN net/hour or higher
  - **Rate with UDT certificate:** 25.00 PLN net/hour
  
  **Schedule:**
  
  - 3 shifts of 8-10 hours (currently no 12-hour shifts)
  
  **Benefits:**
  
  - Accommodation: 450 PLN per person, deducted from wages
  - Allowance for own housing: 300 PLN
  - Company-provided transport and work clothing
  - Free hot meals
  - Opportunity to apply for a residence permit
  - Free UDT certification for forklift operation
      `,
    },
    {
      id: 4,
      country: 'poland',
      title: 'Warehouse Worker at Carrefour (Piotrków)',
      image: vacancy4Image,
      shortDescription: 'Work involves operating electric pallet jacks with scanners.',
      fullDescription: `
  **Candidates:** Men, women, and couples
  
  **Warehouse:** ID Logistics Carrefour – one of the largest food warehouses in Poland.
  
  **Wages:** 22.30 - 30 PLN/hour, depending on performance.
  
  **Departments:**
  
  - **Fresh Department:** Temperature ranges from +4 to +6°C, handling dairy, meat, and vegetables.
    - Free meal provided (main course).
    - Free set of clothing provided (pants, jacket, warm windbreaker, steel-toe boots, vest).
  
  - **Warm Department:** Product sorting with a scanner, organizing goods for transport.
    - Provided equipment includes steel-toe boots, pants, and a vest.
  
  - **Order Preparation:** Order picking with a headset (instructions in Polish or Russian).
  
  **Work Schedule:**
  
  - Two shifts of 8-12 hours, 5-6 days a week.
  - Payment on the 20th of each month, with 35-40 minutes of paid breaks.
  
  **Accommodation:**
  
  - Hostel in Piotrków Trybunalski, 2-4 people per room (couples’ rooms available), 15 PLN/day.
  - Amenities include Wi-Fi, fridge, and washing machine.
  - Free 30-40 minute transportation to work by company vehicle.
  
  **Initial Expenses:**
  
  - 150 PLN for a medical certificate
  - 150 PLN for electric cart certification (waived if you already have it)
  - 60 PLN for steel-toe boots.
      `,
    },
    {
      id: 5,
      country: 'poland',
      title: 'Warehouse Worker at EURO RTV AGD',
      image: vacancy5Image,
      shortDescription: 'Retail of electronics, appliances, and more. No Polish required.',
      fullDescription: `
  **Company Focus:** Retail of electronics, appliances, TVs, computers, tablets, phones, cameras, gaming consoles, and accessories.
  
  **Candidates:** Men, women, and couples
  
  **Wages:**
  
  - 22.61 PLN net/hour + bonuses, paid on the 20th of each month.
  
  **Schedule:**
  
  - Two shifts, each lasting 12 hours, 6 days per week (240-288 hours/month), with rotating days off.
  - Employment is through an **Umowa Zlecenie** contract.
  
  **Location:** Sokołów (10 km from Warsaw).
  
  **Responsibilities:**
  
  - Working with a scanner; no forklift use.
  - Heaviest item to lift is a microwave.
  - Duties include picking, sorting, packing products, labeling, arranging items, loading, and unloading.
  
  **Accommodation:**
  
  - Hostel, apartment, or shared house with 3-4 people per room (couples’ rooms available), 15 PLN/day.
  - Includes essential amenities (Wi-Fi, fridge, washing machine).
  - Free transportation (15-20 minutes).
  
  **Additional Info:**
  
  - The first day includes a mandatory safety briefing and training.
  - No language or experience required.
  - The warehouse provides free clothing and footwear.
  - A dining area is available with options to bring your own food; vending machines for snacks and coffee are on-site.
  - Weekly or bi-weekly advances are available, with the first advance after five days of work.
  - Assistance with work permit processing in Poland.
      `,
    },
    {
      id: 6,
      country: 'poland',
      title: 'Postal Service Worker at IN POST (Mszczonów)',
      image: vacancy6Image,
      shortDescription: 'Sorting parcels in the warehouse, receiving and dispatching parcels.',
      fullDescription: `
  **Location:** Mszczonów
  
  **Employment Contract:** Umowa Zlecenia
  
  **Position:** Postal Service Worker – sorting parcels in the warehouse, receiving and dispatching parcels, maintaining workplace cleanliness.
  
  **Net Wages:**
  
  - 23.50 PLN/hour (over 26 years old)
  - 29.00 PLN/hour (under 26 with student status)
  - **Bonuses:** 200–700 PLN for 100% attendance
  
  **Salary:** Paid from the 20th of each month.
  
  **Schedule:**
  
  - Shifts of 8-10 hours from 18:00 – 02:00 or 20:00 – 04:00, 180-220 hours per month.
  
  **Accommodation:**
  
  - Apartment-style lodging, 3-4 people per room, 28 PLN/day, 20-minute walk to work.
  
  **Additional Info:**
  
  - First day includes safety briefing and training. No language or experience required.
  - Warehouse provides free clothing and footwear.
  - Dining area with vending machines; option to bring your own food.
  - Each worker receives a locker key.
  
  **Salary Payment:**
  
  - Paid to a bank card; weekly or bi-weekly advances available, first advance after five days of work.
  - Assistance with residence permits is provided.
      `,
    },
  
    // Вакансии в Чехии
    {
      id: 7,
      country: 'czech',
      title: 'Warehouse Worker at ROHLIK',
      image: vacancy7Image,
      shortDescription: 'Position: Warehouse Worker (Picker)',
      fullDescription: `
  **Company:** ROHLIK Warehouse  
  **Position:** Warehouse Worker (Picker)  
  
  ### Why Choose Us:
  
  - **Official Employment:** We offer official employment.
  - **Timely Payment:** Wages are always paid on time, with a full settlement before your return home. We can also pay to a Ukrainian bank account.
  - **Advances:** Advances are available 1-2 times a week.
  - **Housing:** Accommodation is provided if needed.
  - **Support:** Full assistance upon arrival in the Czech Republic, including help with necessary documents.
  - **Training:** We provide training before you start work.
  - **Client-Centered Approach:** If needed, we can offer other job options.
  - **Personal Approach:** Always ready to meet for face-to-face communication.
  
  ### Candidate Requirements:
  
  - **Age:** 18 – 50 years old.
  - **Documentation:** Must have a Czech work visa or EU passport.
  - **Candidates:** Men, women, and couples are welcome.
  
  ### Job Description:
  
  We invite you to join as a warehouse picker. Your main responsibility is to collect items according to an order slip. You’ll use a handheld scanner displaying the item name, which you’ll scan, place in a basket, and send further along in the warehouse. The job has a set productivity target, and training is provided. The first 7 days are paid hourly; after that, performance standards apply. If the target is not met, up to two hours may be deducted per day, but maintaining 90% of the target can prevent this. The work is not physically demanding but involves significant walking. The warehouse includes both warm and cold sections (temperature around 9-10°C).
  
  ### Payment:
  
  - For employees with **3-month work visas** or **temporary protection visas**:
    - 165 CZK/hour for the first 150 hours worked.
    - 170 CZK/hour after 150 hours.
    - 180 CZK/hour after 2000 hours worked.
  
  - For employees with **long-term documentation**:
    - 185 CZK/hour (for the first month, during employer transition).
    - 170 CZK/hour after 150 hours.
    - 180 CZK/hour after 2000 hours worked.
  
  Social benefits are covered by the company. Payment is made between the 15th-20th of each month for the previous month, with the option for weekly advances.
  
  ### Accommodation:
  
  Accommodation is available for a fee in shared rooms for 2-3 people at 5000 CZK per month, deducted from salary.
  
  ### Additional:
  
  Special work shoes with reinforced toes are required on the warehouse floor. You can purchase them yourself, or the company can provide them for 800 CZK, which will be deducted from your salary. Dress code is casual, and a high-visibility vest is provided free of charge.
      `,
    },
  ];
  