// src/pages/CountryVacancies.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { vacancies } from '../data/vacancies';

function CountryVacancies() {
  const { country } = useParams();
  const countryVacancies = vacancies.filter(
    (vacancy) => vacancy.country.toLowerCase() === country.toLowerCase()
  );

  return (
    <main className="container mx-auto py-12 mt-20">
      <h2 className="text-4xl font-bold text-center mb-8">
        Vacancies in {country.charAt(0).toUpperCase() + country.slice(1)}
      </h2>
      <div className="grid md:grid-cols-2 gap-8">
        {countryVacancies.map((vacancy) => (
          <div
            key={vacancy.id}
            className="bg-white shadow-lg rounded-lg overflow-hidden"
          >
            <img
              src={vacancy.image}
              alt={vacancy.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-2">{vacancy.title}</h3>
              <p className="mb-4">{vacancy.shortDescription}</p>
              <Link
                to={`/vacancy/${country}/${vacancy.id}`}
                className="text-primary hover:underline"
              >
                Read More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}

export default CountryVacancies;
