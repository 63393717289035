// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import ProcessSteps from './pages/ProcessSteps';
import ApplicationForm from './pages/ApplicationForm';
import ContactUs from './pages/ContactUs';
import Vacancy from './pages/Vacancy';
import CountryVacancies from './pages/CountryVacancies';
import VacancyDetails from './pages/VacancyDetails';

function App() {
  return (
    <Router>
      <Header />
      <div className="pt-20">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/vacancy" element={<Vacancy />} />
          <Route path="/vacancy/:country" element={<CountryVacancies />} />
          <Route path="/vacancy/:country/:id" element={<VacancyDetails />} />
          <Route path="/process-steps" element={<ProcessSteps />} />
          <Route path="/application-form" element={<ApplicationForm />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
