// src/components/Testimonials.js
import React from 'react';

function Testimonials() {
  const testimonials = [
    {
      name: 'Rahul Sharma',
      feedback: 'EuroVisaHelp made the visa process effortless. Highly recommended!',
      photo: '/images/client1.jpg',
    },
    {
      name: 'Anjali Singh',
      feedback: 'Professional and efficient service. Got my visa in no time.',
      photo: '/images/client2.jpg',
    },
  ];

  return (
    <section className="bg-neutral py-12">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8">What Our Clients Say</h2>
        <div className="grid md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg p-6 flex items-center">
              <img
                src={testimonial.photo}
                alt={testimonial.name}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <p className="italic">"{testimonial.feedback}"</p>
                <p className="mt-4 font-bold">- {testimonial.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
