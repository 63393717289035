// src/pages/AboutUs.js
import React from 'react';

function AboutUs() {
  return (
    <main className="container mx-auto p-8 mt-20">
      <h2 className="text-4xl font-bold mb-6">About Us</h2>
      <div className="prose lg:prose-xl">
        <p>
          Our company is a reliable partner for those seeking to build a career in Europe. We specialize in assisting with overseas employment, providing comprehensive support at every stage — from selecting job openings that match the applicant’s skills and expectations to preparing all necessary documents for visa processing.
        </p>
        <p>
          We have been in the employment market for a long time, allowing us to offer proven solutions and deliver high-quality service to our clients. With our extensive experience, broad network, and the professionalism of our team, we provide only legal and verified job opportunities in countries like Romania, Poland, the Czech Republic, and other EU nations.
        </p>
        <p>
          We handle the entire process, including document translation and preparation, interview training, and support with adapting to the new environment. With us, your chances of successful employment and obtaining a European visa increase significantly.
        </p>
      </div>
      {/* Добавьте больше информации о вашей компании */}
    </main>
  );
}

export default AboutUs;
