// src/components/Partners.js
import React from 'react';

function Partners() {
  const partners = [
    { name: 'Partner 1', logo: '/images/partner1.png' },
    { name: 'Partner 2', logo: '/images/partner2.png' },
    { name: 'Partner 3', logo: '/images/partner3.png' },
    { name: 'Partner 4', logo: '/images/partner4.png' },
    // Добавьте больше партнеров при необходимости
  ];

  return (
    <section className="container mx-auto py-12 px-4">
      <h2 className="text-4xl font-bold text-center mb-8">Our Partners</h2>
      <div className="flex flex-wrap justify-center items-center gap-6">
        {partners.map((partner, index) => (
          <div key={index} className="flex items-center justify-center w-24 h-24">
            <img
              src={partner.logo}
              alt={partner.name}
              className="max-h-full max-w-full object-contain"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Partners;
