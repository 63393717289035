// src/pages/ProcessSteps.js
import React from 'react';
import Process from '../components/Process';

function ProcessSteps() {
  return (
    <main className="mt-20">
      <Process />
    </main>
  );
}

export default ProcessSteps;
