// src/pages/Vacancy.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import polandImage from '../images/poland.jpg';
import czechImage from '../images/czech.jpg';

function Vacancy() {
  const navigate = useNavigate();

  const countries = [
    {
      name: 'Poland',
      image: polandImage,
      description: 'View job vacancies in Poland.',
    },
    {
      name: 'Czech',
      image: czechImage,
      description: 'View job vacancies in Czech Republic.',
    },
  ];

  const handleCountryClick = (country) => {
    navigate(`/vacancy/${country.toLowerCase()}`);
  };

  return (
    <main className="container mx-auto py-12 mt-20">
      <h2 className="text-4xl font-bold text-center mb-8">Job Vacancies</h2>
      <div className="grid md:grid-cols-2 gap-8">
        {countries.map((country, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer"
            onClick={() => handleCountryClick(country.name)}
          >
            <img
              src={country.image}
              alt={country.name}
              className="w-full h-48 object-cover"
            />
            <div className="p-6 text-center">
              <h3 className="text-2xl font-bold mb-2">{country.name}</h3>
              <p>{country.description}</p>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}

export default Vacancy;
