// src/pages/VacancyDetails.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { vacancies } from '../data/vacancies';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function VacancyDetails() {
  const { country, id } = useParams();
  const vacancy = vacancies.find(
    (v) => v.id === parseInt(id) && v.country.toLowerCase() === country.toLowerCase()
  );

  if (!vacancy) {
    return (
      <main className="container mx-auto py-12 mt-20">
        <h2 className="text-2xl font-bold text-center mb-8">Vacancy Not Found</h2>
        <p className="text-center">
          The vacancy you are looking for does not exist. Please go back to the{' '}
          <Link to={`/vacancy/${country}`} className="text-primary hover:underline">
            vacancies page
          </Link>
          .
        </p>
      </main>
    );
  }

  return (
    <main className="container mx-auto py-12 mt-20">
      <Link to={`/vacancy/${country}`} className="text-primary hover:underline mb-4 inline-block">
        &larr; Back to Vacancies
      </Link>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img
          src={vacancy.image}
          alt={vacancy.title}
          className="w-full h-64 object-cover"
        />
        <div className="p-6">
          <h2 className="text-3xl font-bold mb-4">{vacancy.title}</h2>
          <div className="prose max-w-none">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{vacancy.fullDescription}</ReactMarkdown>
          </div>
        </div>
      </div>
    </main>
  );
}

export default VacancyDetails;
