// src/components/VisaPackages.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function VisaPackages() {
  const navigate = useNavigate();

  const packages = [
    {
      country: 'Poland',
      image: 'images/poland.jpg',
      description: 'We assist in obtaining visas to Poland.',
    },
    {
      country: 'Romania',
      image: 'images/romania.jpg',
      description: 'We assist in obtaining visas to Romania.',
    },
    {
      country: 'Slovakia',
      image: 'images/slovakia.jpg',
      description: 'We assist in obtaining visas to Slovakia.',
    },
  ];

  const handleLearnMore = (country) => {
    navigate('/application-form', { state: { selectedCountry: country } });
  };

  return (
    <section className="container mx-auto py-12">
      <h2 className="text-4xl font-bold text-center mb-8">Our Visa Services</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {packages.map((pkg, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img
              src={pkg.image}
              alt={pkg.country}
              className="w-full h-48 object-cover"
            />
            <div className="p-6 text-center">
              <h3 className="text-2xl font-bold mb-2">{pkg.country}</h3>
              <p>{pkg.description}</p>
              <button
                className="mt-4 bg-primary text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                onClick={() => handleLearnMore(pkg.country)}
              >
                Apply Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default VisaPackages;
