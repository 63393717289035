// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Transition } from '@headlessui/react';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const navigation = [
    { name: 'Main', href: '/' },
    { name: 'About Us', href: '/about-us' },
    { name: 'How It Works', href: '/process-steps' },
    { name: 'Vacancy', href: '/vacancy' }, 
    { name: 'Apply Now', href: '/application-form' },
    { name: 'Contact Us', href: '/contact-us' },// Новая вкладка
  ];

  return (
    <header className="bg-white shadow-md fixed w-full z-20">
      <nav className="container mx-auto flex justify-between items-center p-4">
        <Link to="/" className="text-2xl font-bold text-primary">
          EuroVisaHelp
        </Link>
        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-4">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-gray-600 hover:text-primary transition duration-300"
            >
              {item.name}
            </Link>
          ))}
        </div>
        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-600 hover:text-primary focus:outline-none"
          >
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </nav>
      {/* Mobile Menu */}
      <Transition
        show={isOpen}
        enter="transition duration-200 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-100 ease-in"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {(ref) => (
          <div className="md:hidden">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white shadow-md">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={() => setIsOpen(false)}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-primary hover:bg-gray-100 transition duration-300"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        )}
      </Transition>
    </header>
  );
}

export default Header;
