// src/pages/ApplicationForm.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function ApplicationForm() {
  const location = useLocation();
  const { state } = location;

  // Валидационная схема с использованием Yup
  const validationSchema = Yup.object({
    fullName: Yup.string().required('Please enter your full name'),
    email: Yup.string().email('Invalid email').required('Please enter your email'),
    phoneNumber: Yup.string().required('Please enter your phone number'),
    birthDate: Yup.date().required('Please enter your date of birth'),
    nationality: Yup.string().required('Please select your nationality'),
    visaType: Yup.string().required('Please select the type of visa'),
    country: Yup.string().required('Please select the country'),
  });

  // Начальные значения формы
  const [initialValues, setInitialValues] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    birthDate: '',
    nationality: '',
    visaType: '',
    country: '',
  });

  // Состояния для отслеживания количества отправок и ограничения
  const [submissionCount, setSubmissionCount] = useState(0);
  const [isLimitReached, setIsLimitReached] = useState(false);

  useEffect(() => {
    if (state && state.selectedCountry) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        country: state.selectedCountry,
      }));
    }

    // Получение количества отправок из localStorage при загрузке компонента
    const count = parseInt(localStorage.getItem('submissionCount')) || 0;
    setSubmissionCount(count);
    if (count >= 2) {
      setIsLimitReached(true);
    }
  }, [state]);

  // Функция обработки отправки формы
  const handleSubmit = async (values, { resetForm }) => {
    if (isLimitReached) {
      alert('You reach maximum number of submit');
      return;
    }

    try {
      // Отправка данных на серверную функцию через перенаправление /api/*
      const response = await fetch('/api/sendTelegramMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Не удалось отправить сообщение в Telegram');
      }

      alert('Succesfully submit!');
      resetForm();

      // Увеличиваем счетчик отправок и сохраняем в localStorage
      const newCount = submissionCount + 1;
      localStorage.setItem('submissionCount', newCount);
      setSubmissionCount(newCount);
      if (newCount >= 2) {
        setIsLimitReached(true);
      }
    } catch (error) {
      console.error(error);
      alert(`Ошибка: ${error.message}`);
    }
  };

  return (
    <main className="container mx-auto p-8 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Visa Application Form</h2>
      {isLimitReached ? (
        <div className="text-center text-red-500">
          You reach maximum number of submit.
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="max-w-lg mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              {/* Full Name */}
              <div className="mb-4">
                <label htmlFor="fullName" className="block text-gray-700 text-sm font-bold mb-2">
                  Full Name
                </label>
                <Field
                  name="fullName"
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="John Doe"
                />
                <ErrorMessage name="fullName" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              {/* Email */}
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                  Email
                </label>
                <Field
                  name="email"
                  type="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="john.doe@example.com"
                />
                <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              {/* Phone Number */}
              <div className="mb-4">
                <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
                  Phone Number
                </label>
                <Field
                  name="phoneNumber"
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="+48 123 456 789"
                />
                <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              {/* Date of Birth */}
              <div className="mb-4">
                <label htmlFor="birthDate" className="block text-gray-700 text-sm font-bold mb-2">
                  Date of Birth
                </label>
                <Field
                  name="birthDate"
                  type="date"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="birthDate" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              {/* Nationality */}
              <div className="mb-4">
                <label htmlFor="nationality" className="block text-gray-700 text-sm font-bold mb-2">
                  Nationality
                </label>
                <Field
                  as="select"
                  name="nationality"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Select your nationality</option>
                  <option value="India">India</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Other Country">Other Country</option>
                  {/* Добавьте другие страны при необходимости */}
                </Field>
                <ErrorMessage name="nationality" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              {/* Type of Visa */}
              <div className="mb-4">
                <label htmlFor="visaType" className="block text-gray-700 text-sm font-bold mb-2">
                  Type of Visa
                </label>
                <Field
                  as="select"
                  name="visaType"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Select the type of visa</option>
                  <option value="Work Permit - National Visa (Type D)">Work Permit - National Visa</option>
                  <option value="Seasonal Work Permit">Seasonal Work Permit</option>
                  {/* Добавьте другие типы виз при необходимости */}
                </Field>
                <ErrorMessage name="visaType" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              {/* Country */}
              <div className="mb-6">
                <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
                  Country
                </label>
                <Field
                  as="select"
                  name="country"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Select the country</option>
                  <option value="Poland">Poland</option>
                  <option value="Romania">Romania</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Germany">Germany</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Albania">Albania</option>
                </Field>
                <ErrorMessage name="country" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              {/* Submit Button */}
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`${
                    isSubmitting ? 'bg-gray-400' : 'bg-primary hover:bg-blue-700'
                  } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Application'}
                </button>
              </div>
              {/* Информация о оставшихся отправках */}
              <div className="mt-4 text-center text-gray-600">
                Submit left: {2 - submissionCount}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </main>
  );
}

export default ApplicationForm;
