// src/pages/HomePage.js
import React, { useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import Advantages from '../components/Advantages';
import VisaPackages from '../components/VisaPackages';
import Testimonials from '../components/Testimonials';
import Partners from '../components/Partners';
import FAQ from '../components/FAQ';
import AOS from 'aos';
import 'aos/dist/aos.css';

function HomePage() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <HeroSection />
      <Services />
      <Advantages />
      <VisaPackages />
      <Testimonials />
      <Partners />
      <FAQ />
    </>
  );
}

export default HomePage;
